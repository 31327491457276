export const showButton = ref(false);
let timeoutId: number | undefined;

export const handleScrollShowHideEl = (selector: string) => {
  const element = document.getElementById(selector);
  if (element) {
    const rect = element.getBoundingClientRect();
    if (rect.bottom < 0 && !showButton.value) {
      timeoutId = window.setTimeout(() => {
        showButton.value = true;
      }, 100);
    } else if (showButton.value && rect.bottom >= 0) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      showButton.value = false;
    }
  }
};

export const handleScrollToEl = (el: string) => {
  const blockToScroll = document.querySelector(el);
  const header = document.querySelector("header");
  const appBanner = document.querySelector(".app-banner.active");
  const countDownBanner = document.querySelector(".countdown-container");

  if (blockToScroll && header) {
    const headerHeight = header.clientHeight;
    const appBannerHeight = appBanner ? appBanner.clientHeight : 0;
    const countDownBannerHeight = countDownBanner ? countDownBanner.clientHeight : 0;

    const offsetTop =
      blockToScroll.getBoundingClientRect().top +
      window.scrollY -
      headerHeight -
      appBannerHeight -
      countDownBannerHeight;

    window.scrollTo({
      top: offsetTop,
      behavior: "smooth",
    });
  }
};
